/*
@include sp($point-sp) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

@charset "utf-8";
.p-privacy {
  @include sp($point-sp) {
    margin-bottom: 40px;
  }
  @include pc01($point-pc01) {
    margin-bottom: 180px;
  }
  &__lead {
    line-height: 1.6;
    @include sp($point-sp) {
      font-size: 14px;
      margin-bottom: 40px;
    }
    @include pc01($point-pc01) {
      font-size: 16px;
      margin-bottom: 60px;
    }
  }
  &__module {
    @include sp($point-sp) {
      margin-bottom: 20px;
    }
    @include pc01($point-pc01) {
      margin-bottom: 60px;
    }
  }
  &__text {
    line-height: 1.6;
  }
}